import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import settings from './settings/reducer';
import starter from './starter/reducer';
import authUser from './auth/reducer';
import radioPlayer from './radioPlayer/reducer'
import podcastPlayer from './podcastPlayer/reducer'
import tvPlayer from './tvPlayer/reducer'
import committeePlayer from './committeePlayer/reducer'
import youtubePlayer from './youtubePlayer/reducer'
import { combineReducers } from 'redux';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


const middlewares = [thunkMiddleware];

const authConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2
}

const settingsConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2
}

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2
}

const starterConfig = {
  key: 'starter',
  storage: storage,
  stateReconciler: autoMergeLevel2
}

const radioPlayerConfig = {
  key: 'radioPlayer',
  storage: storage,
  stateReconciler: autoMergeLevel2
}

const podcastPlayerConfig = {
  key: 'podcastPlayer',
  storage: storage,
  stateReconciler: autoMergeLevel2
}

const tvPlayerConfig = {
  key: 'tvPlayer',
  storage: storage,
  stateReconciler: autoMergeLevel2
}

const committeePlayerConfig = {
  key: 'committeePlayer',
  storage: storage,
  stateReconciler: autoMergeLevel2
}

const youtubePlayerConfig = {
  key: 'youtubePlayer',
  storage: storage,
  stateReconciler: autoMergeLevel2
}

const rootReducers = combineReducers({
    auth: persistReducer(authConfig, authUser),
    settings: persistReducer(settingsConfig, settings),
    starter: persistReducer(starterConfig, starter),
    radioPlayer: persistReducer(radioPlayerConfig, radioPlayer),
    podcastPlayer: persistReducer(podcastPlayerConfig, podcastPlayer),
    tvPlayer: persistReducer(tvPlayerConfig, tvPlayer),
    youtubePlayer: persistReducer(youtubePlayerConfig, youtubePlayer),
    committeePlayer: persistReducer(committeePlayerConfig, committeePlayer),
  });

//const pReducer = persistReducer(persistConfig, rootReducers);
//const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose();
const composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? 
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) : compose;

var the_store = createStore(rootReducers, {}, composeEnhancer(applyMiddleware(...middlewares)));
if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
        const nextRootReducer = require('./reducers');
        the_store.replaceReducer(nextRootReducer);
    });
}

export const store = the_store;
export const persistor = persistStore(store);