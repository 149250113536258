import {
    YOUTUBE_UPDATE_BEGIN_TIME_CLIP,
    YOUTUBE_UPDATE_END_TIME_CLIP,
    YOUTUBE_UPDATE_ABS_BEGIN_TIME_CLIP,
    YOUTUBE_UPDATE_ABS_END_TIME_CLIP,
    YOUTUBE_UPDATE_TIMELINE_START,
    YOUTUBE_DISPLAY_CLIP_SLIDER,
    YOUTUBE_SET_TRANSCRIPT,
    YOUTUBE_SET_MODAL_DETAILS,
    YOUTUBE_SET_MODAL_CHANNEL_DATA,
    YOUTUBE_SET_CLIP_TYPE,
    YOUTUBE_SET_CLIP_LENGTH,
    YOUTUBE_SET_PLAYER_TIME,
    YOUTUBE_SET_THE_MANIFEST,
    YOUTUBE_CLEAR_MODAL_DETAILS,
    YOUTUBE_CLEAR_MODAL_CHANNEL_DATA,
    YOUTUBE_SET_SLIDER_RANGE,
    YOUTUBE_SET_SEGMENT_INDEX,
    YOUTUBE_SET_MANIFEST_STRING,
    YOUTUBE_SET_MANIFEST_ROOT,
    YOUTUBE_RESET_YOUTUBE,
    YOUTUBE_SET_TRANSCRIPT_TEXT,
    YOUTUBE_SET_CLIP_TITLE,
    YOUTUBE_SET_CLIP_CHANNEL_ID,
    YOUTUBE_SET_SRT,
    YOUTUBE_SET_CLIP_PREVIEW_IMAGE,
    YOUTUBE_SET_CLIP_BACKGROUND_VIDEO,
    YOUTUBE_SET_IS_169_CROP_ON,
    YOUTUBE_SET_IS_916_CROP_ON,
    YOUTUBE_SET_IS_11_CROP_ON,
    YOUTUBE_SET_CROP_ASPECT,
    YOUTUBE_SET_CROP_OBJ,
    YOUTUBE_SET_START_CLIP_ID,
    YOUTUBE_SET_END_CLIP_ID,
    YOUTUBE_SET_PREVIOUS_CLIP_ID,
    YOUTUBE_SET_NEXT_CLIP_ID,
    YOUTUBE_SET_PLAYER_RANDOM_STRING,
    YOUTUBE_SET_CLIP_EARLIEST_DATE,
    YOUTUBE_SET_CLIP_LATEST_DATE,
    YOUTUBE_SET_CLIP_CAPTIONS_EARLIEST_DATE,
    YOUTUBE_SET_CLIP_CAPTIONS_LATEST_DATE,
    YOUTUBE_SET_CAPTIONS,
    YOUTUBE_SET_PLAYER_CURRENT_TIME,
    YOUTUBE_SET_SEGMENTS,
    YOUTUBE_SET_ADDED_TIME,
    YOUTUBE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_START,
    YOUTUBE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_END,
    YOUTUBE_SET_ALIGNED_GLOBALLY,
    YOUTUBE_SET_ALIGNED_LOCALLY,
    YOUTUBE_SET_ADDED_PLAYER_TIME,
    YOUTUBE_SET_CLIP_LOAD_BUTTON,
    YOUTUBE_SET_TOTAL_CAPTION_ADJUSTMENT
} from '../actionTypes';

const INIT_STATE = {
    YOUTUBE_beginTimeClip: 0,
    YOUTUBE_endTimeClip: 0,
    YOUTUBE_absBeginTimeClip: -1,
    YOUTUBE_absEndTimeClip: 0,
    YOUTUBE_displayClipSlider: false,
    YOUTUBE_timelineStart: 0,
    YOUTUBE_modalDetails: null,
    YOUTUBE_modalChannelData: null,
    YOUTUBE_transcript: [],
    YOUTUBE_clipType: 'start',
    YOUTUBE_clipLength: 5,
    YOUTUBE_playerTime: 0,
    YOUTUBE_segmentIndex: 0,
    YOUTUBE_sliderRange: {min: 0, max: 100},
    YOUTUBE_theManifest: null,
    YOUTUBE_manifestString: null,
    YOUTUBE_manifestRoot: null,
    YOUTUBE_transcriptText: null,
    YOUTUBE_clipTitle: "",
    YOUTUBE_clipChannelId: null,
    YOUTUBE_clipPreviewImage: null,
    YOUTUBE_clipBackgroundVideo: null,
    YOUTUBE_srt: null,
    YOUTUBE_is169CropOn: false,
    YOUTUBE_is916CropOn: false,
    YOUTUBE_is11CropOn: false,
    YOUTUBE_cropAspect: 16 / 9,
    YOUTUBE_cropObj: { "unit":"%", "x":0, y:0, width:100, height:100 },
    YOUTUBE_startClipId: null,
    YOUTUBE_endClipId: null,
    YOUTUBE_previousClipId: null,
    YOUTUBE_nextClipId: null,
    YOUTUBE_playerRandomString: null,
    YOUTUBE_clipEarliestDate: null,
    YOUTUBE_clipLatestDate: null,
    YOUTUBE_clipCaptionsEarliestDate: null,
    YOUTUBE_clipCaptionsLatestDate: null,
    YOUTUBE_captions: [],
    YOUTUBE_playerCurrentTime: 0,
    YOUTUBE_segments: [],
    YOUTUBE_addedTime: 0,
    YOUTUBE_individualCaptionAdjustmentStart: [],
    YOUTUBE_individualCaptionAdjustmentEnd: [],
    YOUTUBE_alignedGlobally: false,
    YOUTUBE_alignedLocally: false,
    YOUTUBE_addedPlayerTime: 0,
    YOUTUBE_clipLoadButton: false,
    YOUTUBE_totalCaptionAdjustment: 0
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case YOUTUBE_UPDATE_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                YOUTUBE_beginTimeClip:action.payload
            };
        case YOUTUBE_UPDATE_END_TIME_CLIP:
            return { 
            ...state, 
                YOUTUBE_endTimeClip:action.payload
            };
        case YOUTUBE_UPDATE_ABS_BEGIN_TIME_CLIP:
            return { 
                ...state, 
                YOUTUBE_absBeginTimeClip:action.payload
            };
        case YOUTUBE_UPDATE_ABS_END_TIME_CLIP:
            return { 
            ...state, 
                YOUTUBE_absEndTimeClip:action.payload
            };
        case YOUTUBE_DISPLAY_CLIP_SLIDER:
            return { 
            ...state, 
                YOUTUBE_displayClipSlider:action.payload
            };
        case YOUTUBE_UPDATE_TIMELINE_START:
            return { 
            ...state, 
                YOUTUBE_timelineStart:action.payload
            };
        case YOUTUBE_SET_TRANSCRIPT:
            return { 
            ...state, 
                YOUTUBE_transcript:action.payload
            };
        case YOUTUBE_SET_MODAL_DETAILS:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.YOUTUBE_modalDetails);
                return {
                    ...state,
                    YOUTUBE_modalDetails: newState,
                };
            } else {
                return {
                    ...state,
                    YOUTUBE_modalDetails: {
                        ...state.YOUTUBE_modalDetails,
                        ...action.payload,
                    },
                };
            }
        case YOUTUBE_CLEAR_MODAL_DETAILS:
            return { 
                ...state, 
                    YOUTUBE_modalDetails:null
                };
        case YOUTUBE_SET_MODAL_CHANNEL_DATA:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.YOUTUBE_modalChannelData);
                return {
                    ...state,
                    YOUTUBE_modalChannelData: newState,
                };
            } else {
                return {
                    ...state,
                    YOUTUBE_modalChannelData: {
                        ...state.YOUTUBE_modalChannelData,
                        ...action.payload,
                    },
                };
            }
        case YOUTUBE_SET_SLIDER_RANGE:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.YOUTUBE_sliderRange);
                return {
                    ...state,
                    YOUTUBE_sliderRange: newState,
                };
            } else {
                return {
                    ...state,
                    YOUTUBE_sliderRange: {
                        ...state.YOUTUBE_sliderRange,
                        ...action.payload,
                    },
                };
            }
        case YOUTUBE_CLEAR_MODAL_CHANNEL_DATA:
            return { 
                ...state, 
                    YOUTUBE_modalChannelData:null
                    };
        case YOUTUBE_SET_CLIP_TYPE:
            return { 
            ...state, 
                YOUTUBE_clipType:action.payload
            };
        case YOUTUBE_SET_CLIP_LENGTH:
            return { 
            ...state, 
                YOUTUBE_clipLength:action.payload
            };
        case YOUTUBE_SET_PLAYER_TIME:
            return { 
            ...state, 
                YOUTUBE_playerTime:action.payload
            };
        case YOUTUBE_SET_THE_MANIFEST:
            return { 
            ...state, 
                YOUTUBE_theManifest:action.payload
            };
        case YOUTUBE_SET_MANIFEST_STRING:
            return { 
            ...state, 
                YOUTUBE_manifestString:action.payload
            };
        case YOUTUBE_SET_MANIFEST_ROOT:
            return { 
            ...state, 
                YOUTUBE_manifestRoot:action.payload
            };    
        case YOUTUBE_SET_SEGMENT_INDEX:
            return { 
            ...state, 
                YOUTUBE_segmentIndex:action.payload
            };
        case YOUTUBE_SET_TRANSCRIPT_TEXT:
            return { 
            ...state, 
                YOUTUBE_transcriptText:action.payload
            };
        case YOUTUBE_SET_CLIP_TITLE:
            return { 
            ...state, 
                YOUTUBE_clipTitle:action.payload
            };
        case YOUTUBE_SET_CLIP_CHANNEL_ID:
            return { 
            ...state, 
                YOUTUBE_clipChannelId:action.payload
            };
        case YOUTUBE_SET_CLIP_PREVIEW_IMAGE:
            return { 
            ...state, 
                YOUTUBE_clipPreviewImage:action.payload
            };
        case YOUTUBE_SET_CLIP_BACKGROUND_VIDEO:
            return { 
            ...state, 
                YOUTUBE_clipBackgroundVideo:action.payload
            };
        case YOUTUBE_SET_SRT:
            return { 
            ...state, 
                YOUTUBE_srt:action.payload
            };
        case YOUTUBE_SET_IS_169_CROP_ON:
            return { 
            ...state, 
                YOUTUBE_is169CropOn:action.payload
            };    
        case YOUTUBE_SET_IS_916_CROP_ON:
            return { 
            ...state, 
                YOUTUBE_is916CropOn:action.payload
            };    
        case YOUTUBE_SET_IS_11_CROP_ON:
            return { 
            ...state, 
                YOUTUBE_is11CropOn:action.payload
            };    
        case YOUTUBE_SET_CROP_ASPECT:
            return { 
            ...state, 
                YOUTUBE_cropAspect:action.payload
            };
        case YOUTUBE_SET_START_CLIP_ID:
            return { 
            ...state, 
                YOUTUBE_startClipId:action.payload
            };
        case YOUTUBE_SET_END_CLIP_ID:
            return { 
            ...state, 
                YOUTUBE_endClipId:action.payload
            };
        case YOUTUBE_SET_PREVIOUS_CLIP_ID:
            return { 
            ...state, 
                YOUTUBE_previousClipId:action.payload
            };
        case YOUTUBE_SET_NEXT_CLIP_ID:
            return { 
            ...state, 
                YOUTUBE_nextClipId:action.payload
            };
        case YOUTUBE_SET_PLAYER_RANDOM_STRING:
            return { 
            ...state, 
                YOUTUBE_playerRandomString:action.payload
            };
        case YOUTUBE_SET_CLIP_EARLIEST_DATE:
            return { 
            ...state, 
                YOUTUBE_clipEarliestDate:action.payload
            };
        case YOUTUBE_SET_CLIP_LATEST_DATE:
            return { 
            ...state, 
                YOUTUBE_clipLatestDate:action.payload
            };
        case YOUTUBE_SET_CLIP_CAPTIONS_EARLIEST_DATE:
            return { 
            ...state, 
                YOUTUBE_clipCaptionsEarliestDate:action.payload
            };
        case YOUTUBE_SET_CLIP_CAPTIONS_LATEST_DATE:
            return { 
            ...state, 
                YOUTUBE_clipCaptionsLatestDate:action.payload
            };
        case YOUTUBE_SET_CAPTIONS:
            return { 
            ...state, 
                YOUTUBE_captions:action.payload
            };
        case YOUTUBE_SET_PLAYER_CURRENT_TIME:
            return { 
            ...state, 
                YOUTUBE_playerCurrentTime:action.payload
            };
        case YOUTUBE_SET_SEGMENTS:
            return { 
            ...state, 
                YOUTUBE_segments:action.payload
            };
        case YOUTUBE_SET_ADDED_TIME:
            return { 
            ...state, 
                YOUTUBE_addedTime:action.payload
            };
        case YOUTUBE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_START:
            return { 
            ...state, 
                YOUTUBE_individualCaptionAdjustmentStart:action.payload
            };
        case YOUTUBE_SET_INDIVIDUAL_CAPTION_ADJUSTMENT_END:
            return { 
            ...state, 
                YOUTUBE_individualCaptionAdjustmentEnd:action.payload
            };
        case YOUTUBE_SET_ALIGNED_GLOBALLY:
            return { 
            ...state, 
                YOUTUBE_alignedGlobally:action.payload
            };
        case YOUTUBE_SET_ALIGNED_LOCALLY:
            return { 
            ...state, 
                YOUTUBE_alignedLocally:action.payload
            };
        case YOUTUBE_SET_ADDED_PLAYER_TIME:
            return { 
            ...state, 
                YOUTUBE_addedPlayerTime:action.payload
            };
        case YOUTUBE_SET_CLIP_LOAD_BUTTON:
            return { 
            ...state, 
                YOUTUBE_clipLoadButton:action.payload
            };
        case YOUTUBE_SET_TOTAL_CAPTION_ADJUSTMENT:
            return { 
            ...state, 
                YOUTUBE_totalCaptionAdjustment:action.payload
            };
        case YOUTUBE_SET_CROP_OBJ:
            if (typeof action.payload === 'function') {
                const newState = action.payload(state.YOUTUBE_cropObj);
                return {
                    ...state,
                    YOUTUBE_cropObj: newState,
                };
            } else {
                return {
                    ...state,
                    YOUTUBE_cropObj: {
                        ...state.YOUTUBE_cropObj,
                        ...action.payload,
                    },
                };
            };      
        case YOUTUBE_RESET_YOUTUBE:
            return INIT_STATE;
        default: 
            return { 
                ...state 
            };
    }
}